import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-past-events',
  templateUrl: './past-events.component.html',
  styleUrls: ['./past-events.component.scss']
})
export class PastEventsComponent implements OnInit {
  pdfSrc;
  items: any[] = [
    {id: 'assets/document/Annual Report - 2024.pdf', name: 'Annual Report 2024'},
  ];
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.items[0].id);
  }

}
